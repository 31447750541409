import React from 'react';
import { Link } from 'gatsby';
import FluidImage from '../components/fluid-image.js';

const SerialSummaryTemplate = ({ pageContext }) => {
  const {
    series,
    pages
  } = pageContext;

  return (
    <article className='py-16 w-full'>
      <h2 className='text-center text-4xl mb-8'>{series}</h2>
      <ul className='flex w-full flex-wrap justify-center align-center'>
        {pages.map(page => (
          <li key={series + page.page.toString()} className='w-1/5 mx-2 flex'>
            <Link to={`${page.seriesSlug}/${page.page.toString()}`} className='flex flex-col w-full justify-center align-center'>
              <FluidImage image={page.image} alt='a comics page' />
              <p className='text-center'>Page {page.page.toString()}</p>
            </Link>
          </li>
        ))};
      </ul>
    </article>
  );
};

export default SerialSummaryTemplate;
